import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import clsx from 'clsx';

const Image = ({ src, alt, width, height, objectFit }) => (
  <div className="image-wrapper" style={{ '--image-ratio': `${(height / width) * 100}%` }}>
    <img
      src={src}
      alt={alt}
      width={width}
      height={height}
      className={clsx('image-content', { [`object-fit-${objectFit}`]: objectFit })}
      loading="lazy"
    />
  </div>
);

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  src: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  objectFit: PropTypes.oneOf(['contain', 'cover', 'fill', 'scale-down', 'none', undefined]),
};

Image.defaultProps = {
  objectFit: undefined,
};

export default Image;
