import { defineMessages } from 'react-intl';

export default defineMessages({
  companyDescription: {
    id: 'site.components.footer.companyDescription',
    defaultMessage:
      "Improve your business processes in a scalable and efficient way using Afosto's revolutionary software solutions.",
    description: 'description of the company in the footer',
  },
  ctaTitle: {
    id: 'site.components.footer.cta.title',
    defaultMessage: 'Be ready for the future with Afosto OMS system',
    description: 'default cta title for the footer',
  },
  ctaUrl: {
    id: 'site.components.footer.cta.url',
    defaultMessage: '/request-demo/',
    description: 'default cta url for the footer',
  },
  ctaLabel: {
    id: 'site.components.footer.cta.label',
    defaultMessage: 'Book a demo',
    description: 'default cta label for the footer',
  },
  socialLinkLabel: {
    id: 'site.components.footer.socialLink.label',
    defaultMessage: 'Afosto on {label}',
    description: 'label of the social link in the footer',
  },
});
