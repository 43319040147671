import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { usePopper } from 'react-popper';
import clsx from 'clsx';
import { useClickAway } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import Flag from '../../../Flag';
import './styles.scss';
import sortArrayByProperty from '../../../../utils/sortArrayByProperty';
import Button from '../../../Button';

const getLangForLocale = locale => {
  switch (locale.toLowerCase()) {
    case 'en':
      return { iso: 'gb', label: 'English' };
    case 'nl-nl':
      return { iso: 'nl', label: 'Nederlands' };
    default:
      return locale;
  }
};

const LanguageSwitch = ({ locale, localizations }) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [triggerElement, setTriggerElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(triggerElement, popperElement, {
    placement: 'top-start',
  });
  const innerRef = useRef(null);

  useClickAway(innerRef, event => {
    if (isOpen && event.target !== triggerElement) {
      setIsOpen(false);
    }
  });

  const mergedLocalizations = [
    ...localizations,
    {
      locale,
      slug: location.pathname,
    },
  ];

  const activeLang = getLangForLocale(locale);

  if (mergedLocalizations.length === 1) {
    return null;
  }

  return (
    <>
      <Button
        color="link"
        className="language-switch-dropdown-trigger px-0"
        outline
        type="button"
        ref={setTriggerElement}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Flag iso={activeLang.iso} style={{ pointerEvents: 'none' }} className="mr-12" />
        {activeLang.label}
      </Button>
      <AnimatePresence>
        {isOpen && (
          <div ref={setPopperElement} className={clsx('dropdown')} style={styles.popper} {...attributes.popper}>
            <motion.div
              initial={{ opacity: 0, y: '8px' }}
              animate={{ opacity: 1, y: 0, display: 'block' }}
              exit={{ opacity: 0, y: '8px', transitionEnd: { display: 'none' } }}
              transition={{ duration: 0.1, ease: 'easeOut', type: 'tween' }}
              ref={innerRef}
              className="language-switch-dropdown dropdown-menu show"
            >
              {sortArrayByProperty(mergedLocalizations, 'locale').map(lang => {
                const langData = getLangForLocale(lang.locale);

                return (
                  <Link
                    to={lang.slug === 'hp' ? '/' : `${lang.slug.startsWith('/') ? '' : '/'}${lang.slug}`}
                    className="dropdown-item typography body"
                    onClick={() => setIsOpen(false)}
                  >
                    <Flag iso={langData.iso} className="mr-12" />
                    {langData.label}
                  </Link>
                );
              })}
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  );
};

LanguageSwitch.propTypes = {
  locale: PropTypes.string.isRequired,
  localizations: PropTypes.array.isRequired,
};

export default LanguageSwitch;
