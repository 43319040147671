import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useKey, useMedia, useWindowScroll } from 'react-use';
import clsx from 'clsx';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faAngleDown, faBars } from '@fortawesome/pro-regular-svg-icons';
import { Collapse } from 'react-collapse';
import { AnimatePresence, motion } from 'framer-motion';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../Button';
import Icon from '../Icon';
import Typography from '../Typography';
import Logo from '../../images/logo.svg';
import './style.scss';
import NavSubItem from '../NavSubItem';
import Card from '../Card';
import CardBody from '../CardBody';
import findObjectInArray from '../../utils/findObjectInArray';
import Divider from '../Divider';
import isRelativeUri from '../../utils/isRelativeUri';
import Grid from '../Grid';
import translations from './translations';

const SCROLL_DISTANCE = 100;

const Header = ({ location, variant, color, bgColor, title, locale }) => {
  const intl = useIntl();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [subsubnavIsOpen, setSubsubnavIsOpen] = useState(false);
  const [openMenuItem, setOpenMenuItem] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);
  const { y } = useWindowScroll();
  const isMobile = useMedia('(max-width: 991px)');

  const data = useStaticQuery(graphql`
    query headerNavigationQuery {
      allStrapiNavigation(filter: { code: { regex: "/header/" } }) {
        nodes {
          code
          label
          locale
          trees {
            id
            tree {
              id
              title
              url
              is_target_blank
              is_nofollow
              anchor
            }
            label
          }
        }
      }
      allStrapiTree {
        nodes {
          items {
            title
            url
            description
            icon {
              url
            }
            items {
              title
              url
              is_target_blank
              is_nofollow
              id
              anchor
            }
            is_nofollow
            is_target_blank
          }
          title
          url
          anchor
          id
          is_nofollow
          is_target_blank
          strapiId
          locale
        }
      }
    }
  `);

  const {
    allStrapiNavigation: { nodes: navigations } = {},
    allStrapiTree: { nodes: navTrees },
  } = data;

  const navigation = findObjectInArray(navigations, 'locale', locale);
  const localizedNavTrees = navTrees.filter(tree => tree.locale === locale);

  const navItems = navigation?.trees?.reduce((acc, navItem) => {
    const subTree = findObjectInArray(localizedNavTrees, 'strapiId', navItem.tree.id);
    return [...acc, { ...navItem.tree, ...subTree }];
  }, []);

  const handleClickMenuItem = () => {
    setMenuIsOpen(false);
  };

  const handleClickAngle = item => {
    setOpenMenuItem(item.title !== openMenuItem ? item.title : null);
  };

  const handleHoverMenuItem = item => {
    if (!isMobile) {
      if (item?.items) {
        setHoveredItem(item.title);
      } else {
        setHoveredItem(false);
      }
    }
  };

  const handleHoverOffMenu = () => {
    setHoveredItem(false);
  };

  useKey('Escape', () => setMenuIsOpen(false));

  const currentPageItem = navTrees?.find(
    tree => location?.pathname?.startsWith(tree?.url) && !tree?.anchor?.toLowerCase().includes('footer'),
  );
  const currentSubPage = currentPageItem?.items?.find(item => location?.pathname?.startsWith(item?.url));
  const currentPageSubItems = currentSubPage?.items;

  return (
    <>
      <nav
        className={clsx(
          `navbar ${variant} navbar-${y < SCROLL_DISTANCE ? color : 'light'} flex-column flex-md-row bg-${
            y < SCROLL_DISTANCE ? bgColor : 'white'
          }`,
        )}
      >
        <div className="navbar-inner">
          <div className="container py-10 py-lg-0">
            <div className="header-content">
              <div className="d-flex align-items-center pr-lg-44">
                <Link
                  className="text-center"
                  to={`/${locale === 'en' ? '' : `${locale.split('-')[0]}/`}`}
                  title={title}
                >
                  <Logo height={32} className={`logo-${y < SCROLL_DISTANCE ? color : 'light'}`} />
                </Link>
              </div>
              <div className="d-lg-flex justify-content-lg-center">
                <div className={clsx('navbar-drawer', { in: menuIsOpen })} onMouseLeave={handleHoverOffMenu}>
                  <div className="navbar-drawer-header">
                    <Link className="text-center" to="/" title={title}>
                      <Logo height={32} className="logo-light" />
                    </Link>
                    <Button
                      color="link"
                      className="text-gray-1000 navbar-drawer-close"
                      onClick={() => setMenuIsOpen(false)}
                    >
                      <span className="sr-only">Sluit menu</span>
                      <Icon icon={faTimes} fixedWidth size="lg" />
                    </Button>
                  </div>
                  <ul className="navbar-nav flex-column flex-lg-row">
                    {(navItems || []).map(item => {
                      const Component = item?.url ? Link : 'button';
                      const isRelative = isRelativeUri(item?.url);
                      const url = isRelative && !item?.url.startsWith('/') ? `/${item?.url}` : item?.url;

                      return (
                        <li
                          className={clsx('nav-item py-lg-0 px-lg-20', {
                            active: location?.pathname.includes(item.url),
                          })}
                          key={item.url}
                          onMouseOver={() => handleHoverMenuItem(item)}
                          onFocus={() => handleHoverMenuItem(item)}
                        >
                          <div className="d-flex align-items-center">
                            <Typography
                              variant="body-small"
                              weight="medium"
                              component={Component}
                              to={url}
                              className="nav-link flex-fill py-lg-24"
                              title={item.title}
                              onClick={handleClickMenuItem}
                              {...(item.is_target_blank ? { target: '_blank' } : {})}
                              {...(item.is_no_follow ? { rel: 'nofollow' } : {})}
                            >
                              {item.anchor}
                            </Typography>
                            {item.items?.length > 0 && (
                              <button
                                type="button"
                                className="btn btn-link text-gray-af-110 d-lg-none"
                                onClick={() => handleClickAngle(item)}
                                title={`open ${item.title}`}
                              >
                                <Icon
                                  icon={faAngleDown}
                                  className="ml-auto"
                                  rotation={openMenuItem === item.title ? 180 : 0}
                                />
                              </button>
                            )}
                          </div>
                          <div className="d-lg-none">
                            <Collapse isOpened={openMenuItem === item.title}>
                              <ul className="list-unstyled px-15">
                                {item?.items?.map(subItem => (
                                  <li key={subItem.title}>
                                    <NavSubItem
                                      href={subItem.url}
                                      label={subItem.title}
                                      title={subItem.title}
                                      description={subItem.description}
                                      icon={subItem.icon}
                                      {...(openMenuItem !== item.title ? { tabIndex: '-1' } : {})}
                                      {...(subItem.is_target_blank ? { target: '_blank' } : {})}
                                      {...(subItem.is_no_follow ? { rel: 'nofollow' } : {})}
                                    />
                                  </li>
                                ))}
                              </ul>
                            </Collapse>
                          </div>
                        </li>
                      );
                    })}
                    <li className="ml-lg-auto nav-item py-lg-0 pl-lg-8 d-lg-none">
                      <Typography
                        variant="body-small"
                        component="a"
                        href={locale === 'en' ? 'https://afosto.app/auth/login' : 'https://app.afosto.com/login'}
                        className="nav-link flex-fill py-lg-24"
                        title={intl.formatMessage(translations.login)}
                        onClick={handleClickMenuItem}
                      >
                        <FormattedMessage {...translations.login} />
                      </Typography>
                    </li>
                    <li className="d-lg-none nav-item p-15 mt-auto">
                      <Typography
                        variant="body"
                        component={Link}
                        to={intl.formatMessage(translations.requestDemoUrl)}
                        className="btn btn-primary btn-block"
                        title={intl.formatMessage(translations.requestDemo)}
                        onClick={handleClickMenuItem}
                      >
                        <FormattedMessage {...translations.requestDemo} />
                      </Typography>
                    </li>
                  </ul>
                  <div className="subnav d-none d-lg-block">
                    <AnimatePresence>
                      {hoveredItem && (
                        <motion.div
                          key="menu-wrapper"
                          initial={{ opacity: 0, y: '-8px' }}
                          animate={{ opacity: 1, y: 0, display: 'block' }}
                          exit={{ opacity: 0, y: '-8px', transitionEnd: { display: 'none' } }}
                          transition={{ duration: 0.1, ease: 'easeOut', type: 'tween' }}
                        >
                          <div onMouseLeave={handleHoverOffMenu}>
                            <Card elevation={3}>
                              <CardBody>
                                {(navItems || {})
                                  .filter(group => group.items?.length > 0)
                                  .map(group => (
                                    <React.Fragment key={group.title}>
                                      {hoveredItem === group.title && (
                                        <motion.div
                                          className="d-flex flex-wrap"
                                          initial={{ opacity: 0 }}
                                          animate={{ opacity: 1 }}
                                          exit={{ opacity: 0 }}
                                          transition={{
                                            duration: 0.2,
                                            ease: 'easeOut',
                                            type: 'tween',
                                          }}
                                        >
                                          {(group.items || []).map(item => (
                                            <NavSubItem
                                              icon={item.icon}
                                              label={item.title}
                                              href={item.url}
                                              description={item.description}
                                              key={item.url}
                                              {...(item.is_target_blank ? { target: '_blank' } : {})}
                                              {...(item.is_no_follow ? { rel: 'nofollow' } : {})}
                                            />
                                          ))}
                                        </motion.div>
                                      )}
                                    </React.Fragment>
                                  ))}
                              </CardBody>
                            </Card>
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </div>
                <div className="navbar-drawer-backdrop" onClick={() => setMenuIsOpen(false)} aria-hidden />
              </div>
              <div className="d-flex justify-content-end align-items-center">
                <Typography
                  variant="body-small"
                  component="a"
                  href={locale === 'en' ? 'https://afosto.app/auth/login' : 'https://app.afosto.com/login'}
                  className={clsx(
                    'btn btn-text align-items-end p-0 d-none d-lg-flex',
                    y < SCROLL_DISTANCE && color === 'dark' ? 'text-white' : 'text-gray-af-120',
                  )}
                  title={intl.formatMessage(translations.login)}
                  onMouseOver={handleHoverOffMenu}
                >
                  <FormattedMessage {...translations.login} />
                </Typography>
                <Link
                  className={clsx(
                    'ml-24 btn btn-primary',
                    `text-${y < SCROLL_DISTANCE ? color.replace('dark', 'white').replace('light', 'black') : 'black'}`,
                  )}
                  to={intl.formatMessage(translations.requestDemoUrl)}
                >
                  <FormattedMessage {...translations.freeDemo} />
                </Link>
                <Button
                  color="link"
                  className={clsx(
                    'p-12 ml-8 d-lg-none',
                    `text-${y < SCROLL_DISTANCE ? color.replace('dark', 'white').replace('light', 'black') : 'black'}`,
                  )}
                  type="button"
                  onClick={() => setMenuIsOpen(true)}
                >
                  <span className="sr-only">Open menu</span>
                  <Icon icon={faBars} size="lg" className="text-gray-af-110" />
                </Button>
              </div>
            </div>
          </div>
        </div>

        {currentPageSubItems?.length > 0 && (
          <>
            <div
              className="sub-sub-nav"
              onClick={() => setSubsubnavIsOpen(!subsubnavIsOpen)}
              onKeyDown={event => {
                if (event?.keyCode === 13) {
                  setSubsubnavIsOpen(!subsubnavIsOpen);
                }
              }}
              tabIndex="0"
              role="button"
              aria-pressed="false"
            >
              <Grid container className="py-8 py-lg-16 d-flex justify-content-between">
                <Typography
                  variant="body"
                  component={Link}
                  to={currentSubPage?.url}
                  weight="medium"
                  color={clsx(
                    'hover-underline',
                    y < SCROLL_DISTANCE && color === 'dark' ? 'text-white' : 'gray-af-120',
                  )}
                >
                  {currentSubPage?.title}
                </Typography>

                <Button className="p-6 sub-sub-nav-trigger d-lg-none" color="text" type="button">
                  <Icon icon={faAngleDown} fixedWidth rotation={subsubnavIsOpen ? 180 : 0} />
                </Button>

                <ul className="navbar-nav flex-row align-items-center d-none d-lg-flex">
                  {currentPageSubItems.map(item => {
                    const isRelative = isRelativeUri(item.url);
                    const Component = isRelative ? Link : 'a';
                    return (
                      <li
                        key={item.id}
                        className={clsx('nav-item px-24', {
                          active: location?.pathname.includes(item.url),
                        })}
                      >
                        <Typography
                          variant="body-small"
                          weight="medium"
                          component={Component}
                          title={item.title}
                          {...(isRelative ? { to: item.url } : { href: item.url })}
                          className="nav-link py-0"
                        >
                          {item.anchor}
                        </Typography>
                      </li>
                    );
                  })}
                  <li>
                    <Divider
                      color={y < SCROLL_DISTANCE && color === 'dark' ? 'af-gray-120' : 'gray-300'}
                      orientation="vertical"
                    />
                  </li>
                  <li className="nav-item px-24">
                    <Typography
                      variant="body-small"
                      weight="medium"
                      color={y < SCROLL_DISTANCE && color === 'dark' ? 'primary-400' : 'primary'}
                      component={Link}
                      to={intl.formatMessage(translations.registerUrl, { url: currentSubPage.url })}
                      title={intl.formatMessage(translations.registerTitle)}
                      className="nav-link py-0"
                    >
                      <FormattedMessage {...translations.registerLabel} />
                    </Typography>
                  </li>
                </ul>
              </Grid>
            </div>
            <div className="sub-sub-nav-collapse d-lg-none">
              <Collapse isOpened={subsubnavIsOpen}>
                <Grid container className="py-24">
                  <ul className="navbar-nav">
                    {currentPageSubItems.map(item => {
                      const isRelative = isRelativeUri(item.url);
                      const Component = isRelative ? Link : 'a';
                      return (
                        <li key={item.id} className="nav-item">
                          <Typography
                            variant="body"
                            weight="medium"
                            component={Component}
                            {...(isRelative ? { to: item.url } : { href: item.url })}
                            className="nav-link py-16"
                          >
                            {item.anchor}
                          </Typography>
                        </li>
                      );
                    })}
                    <li className="nav-item py-16">
                      <Link
                        to={intl.formatMessage(translations.registerUrl, {
                          url: currentSubPage.url,
                        })}
                        className="btn btn-primary btn-block"
                      >
                        <FormattedMessage {...translations.registerLabel} />
                      </Link>
                    </li>
                  </ul>
                </Grid>
              </Collapse>
            </div>
          </>
        )}
      </nav>
      {currentPageSubItems?.length > 0 && (
        <div
          className={clsx('sub-sub-nav-backdrop d-lg-none', { open: subsubnavIsOpen })}
          onClick={() => setSubsubnavIsOpen(false)}
          onKeyDown={event => {
            if (event?.keyCode === 13) {
              setSubsubnavIsOpen(false);
            }
          }}
          tabIndex="0"
          role="button"
          aria-pressed="false"
          aria-label="Sluit submenu"
        />
      )}
    </>
  );
};

Header.propTypes = {
  color: PropTypes.oneOf(['light', 'dark']),
  bgColor: PropTypes.oneOf([
    'white',
    'black',
    'muted',
    'primary',
    'secondary',
    'success',
    'warning',
    'info',
    'danger',
    'afosto-dark',
    'transparent',
  ]),
  location: PropTypes.object,
  locale: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'sticky-top', 'fixed-top', 'fixed-bottom']),
};

Header.defaultProps = {
  color: 'light',
  bgColor: 'white',
  location: undefined,
  locale: 'en',
  title: undefined,
  variant: 'sticky-top',
};

export default Header;
