import React from 'react';
import PropTypes from 'prop-types';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { IntlProvider } from 'react-intl';
import { Helmet } from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';
import { siteMetadata } from '../../../gatsby-config';
import CookieConsent from '../CookieConsent';
import '../../scss/main.scss';
import english from '../../translations/en.json';
import dutch from '../../translations/nl.json';

library.add(fab, fal);

const messages = {
  en: english,
  'en-au': english,
  'en-bz': english,
  'en-ca': english,
  'en-ie': english,
  'en-jm': english,
  'en-nz': english,
  'en-za': english,
  'en-tt': english,
  'en-gb': english,
  'en-us': english,
  nl: dutch,
  'nl-be': dutch,
  'nl-nl': dutch,
  be: dutch,
};

const Layout = ({ children, headerOptions, location, hideCta, locale, localizations, slug, ...rest }) => (
  <IntlProvider locale={locale.toLowerCase()} messages={messages[locale.toLowerCase()]}>
    <Helmet>
      {localizations.length > 0 && (
        <link
          rel="alternate"
          href={
            slug === 'hp'
              ? process.env.GATSBY_BASE_URL
              : `${process.env.GATSBY_BASE_URL}${slug?.startsWith('/') ? '' : '/'}${slug ?? ''}`
          }
          hrefLang={locale.split('-')[0]}
          key={locale}
        />
      )}
      {localizations.map(lang => (
        <link
          rel="alternate"
          href={
            lang.slug === 'hp'
              ? process.env.GATSBY_BASE_URL
              : `${process.env.GATSBY_BASE_URL}${lang.slug.startsWith('/') ? '' : '/'}${lang.slug}`
          }
          hrefLang={lang.locale.split('-')[0]}
          key={lang.locale}
        />
      ))}
    </Helmet>
    <Header title={siteMetadata.title} {...headerOptions} location={location} locale={locale} {...rest} />
    {children}
    <Footer
      title={siteMetadata.title}
      author={siteMetadata.author}
      hideCta={hideCta}
      locale={locale}
      localizations={localizations}
    />
    <CookieConsent />
  </IntlProvider>
);

Layout.propTypes = {
  children: PropTypes.any,
  headerOptions: PropTypes.object,
  locale: PropTypes.string,
  localizations: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string,
      slug: PropTypes.string,
    }),
  ),
  location: PropTypes.object.isRequired,
  hideCta: PropTypes.bool,
  slug: PropTypes.string,
};

Layout.defaultProps = {
  children: undefined,
  headerOptions: {},
  locale: 'en',
  localizations: [],
  hideCta: false,
  slug: undefined,
};

export default Layout;
