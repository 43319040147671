import { defineMessages } from 'react-intl';

export default defineMessages({
  login: {
    id: 'actions.login',
    defaultMessage: 'Log in',
    description: 'label of the login action',
  },
  freeDemo: {
    id: 'actions.freeDemo',
    defaultMessage: 'Free trial',
    description: 'label of the free demo action',
  },
  requestDemo: {
    id: 'actions.requestDemo',
    defaultMessage: 'Request demo',
    description: 'label of the request demo action',
  },
  requestDemoUrl: {
    id: 'site.components.header.requestDemoUrl',
    defaultMessage: '/register/',
    description: 'url to request a demo in the header',
  },
  registerUrl: {
    id: 'site.components.header.register.url',
    defaultMessage: '{url}register/',
    description: 'url to register an account in the subheader',
  },
  registerTitle: {
    id: 'site.components.header.register.title',
    defaultMessage: 'Register now for a free trial',
    description: 'title of the button to register an account in the subheader',
  },
  registerLabel: {
    id: 'site.components.header.register.label',
    defaultMessage: 'Try for free',
    description: 'label of the button to register an account in the subheader',
  },
});
