import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, useStaticQuery } from 'gatsby';
import clsx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { faFacebook, faGithub, faLinkedin, faMedium, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import Typography from '../Typography';
import Grid from '../Grid';
import Logo from '../../images/logo.svg';
import './style.scss';
import Divider from '../Divider';
import CtaBlock from '../blocks/CtaBlock';
import isRelativeUri from '../../utils/isRelativeUri';
import findObjectInArray from '../../utils/findObjectInArray';
import translations from './translations';
import LanguageSwitch from './components/LanguageSwitch';
import Icon from '../Icon';

const SOCIAL_LINKS = [
  { label: 'Twitter', url: 'https://twitter.com/afosto/', icon: faTwitter },
  { label: 'Facebook', url: 'https://www.facebook.com/afosto/', icon: faFacebook },
  { label: 'Youtube', url: 'https://www.youtube.com/c/Afosto', icon: faYoutube },
  { label: 'Linkedin', url: 'https://www.linkedin.com/company/afosto', icon: faLinkedin },
  { label: 'Github', url: 'https://github.com/afosto/', icon: faGithub },
  { label: 'Medium', url: 'https://medium.com/afosto', icon: faMedium },
];

const Footer = ({ hideCta, localizations, locale }) => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query footerQuery {
      site {
        siteMetadata {
          author
        }
      }
      allStrapiNavigation(filter: { code: { regex: "/footer/" } }) {
        nodes {
          code
          label
          locale
          trees {
            id
            tree {
              id
              title
              url
              is_target_blank
              is_nofollow
              anchor
            }
            label
          }
        }
      }
      allStrapiTree {
        nodes {
          items {
            title
            url
            description
            icon {
              url
            }
            items {
              title
              url
              is_target_blank
              is_nofollow
              id
              anchor
            }
            is_nofollow
            is_target_blank
          }
          title
          url
          anchor
          id
          is_nofollow
          is_target_blank
          strapiId
          locale
        }
      }
    }
  `);

  const {
    allStrapiNavigation: { nodes: navigations } = {},
    allStrapiTree: { nodes: navTrees },
  } = data;

  const localizedNavigations = navigations.filter(navigation => navigation.locale === locale);
  const navigation = findObjectInArray(localizedNavigations, 'label', 'Footer');
  const localizedNavTrees = navTrees.filter(tree => tree.locale === locale);

  const navItems = navigation?.trees?.reduce((acc, navItem) => {
    const subTree = findObjectInArray(localizedNavTrees, 'strapiId', navItem.tree.id);
    return [...acc, { ...navItem.tree, ...subTree }];
  }, []);

  const bottomNavigation = findObjectInArray(localizedNavigations, 'label', 'Footer bottom');
  const bottomNavItems = bottomNavigation?.trees?.reduce((acc, navItem) => {
    const subTree = findObjectInArray(localizedNavTrees, 'strapiId', navItem.tree.id);
    return [...acc, { ...navItem.tree, ...subTree }];
  }, []);

  return (
    <>
      {!hideCta && (
        <CtaBlock
          contents={[
            {
              title: intl.formatMessage(translations.ctaTitle),
              cta_url_1: intl.formatMessage(translations.ctaUrl),
              cta_label_1: intl.formatMessage(translations.ctaLabel),
            },
          ]}
        />
      )}
      <footer className="pt-64">
        <Grid container>
          <Grid row>
            <Grid column xs={12} sm={8} lg={4} className="pb-56">
              <Logo height={32} className="logo-light mb-20" />
              <Typography color="gray-af-90" className="pr-20 mb-16">
                <FormattedMessage {...translations.companyDescription} />
              </Typography>

              <div className="d-flex align-items-center ml-n4 mb-24">
                {SOCIAL_LINKS.map(({ label, url, icon }) => (
                  <a
                    title={intl.formatMessage(translations.socialLinkLabel, { label })}
                    href={url}
                    className="text-gray-sm-af-70 p-4"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon icon={icon} fixedWidth size="lg" />
                    <span className="sr-only">{label}</span>
                  </a>
                ))}
              </div>

              <LanguageSwitch localizations={localizations} locale={locale} />
            </Grid>
            <div className="w-100 d-lg-none" />
            {(navItems || []).map(menu => {
              const isRelative = isRelativeUri(menu?.url);
              const Component = isRelative ? Link : 'a';
              const url = isRelative && !menu?.url.startsWith('/') ? `/${menu?.url}` : menu?.url;
              const hrefProps = isRelative ? { to: url } : { href: url };

              return (
                <Grid column xs={6} sm={3} lg={2} key={menu?.title}>
                  <Typography
                    variant="body"
                    component={Component}
                    {...hrefProps}
                    {...(menu.is_target_blank ? { target: '_blank' } : {})}
                    {...(menu.is_no_follow ? { rel: 'nofollow' } : {})}
                    color="gray-af-110"
                    className="mt-8 mb-24 d-inline-block footer-link"
                    weight="medium"
                  >
                    {menu?.title}
                  </Typography>
                  {menu?.items && (
                    <ul className="list-unstyled">
                      {menu.items.map((item, idx) => {
                        const isRelativeSub = isRelativeUri(item?.url);
                        const ComponentSub = isRelativeSub ? Link : 'a';
                        const subUrl = isRelativeSub && !item?.url.startsWith('/') ? `/${item?.url}` : item?.url;
                        const subHrefProps = isRelativeSub ? { to: subUrl } : { href: subUrl };

                        return (
                          <li className={clsx({ 'mb-16': idx + 1 !== menu.items.length })} key={item.title}>
                            <Typography
                              component={ComponentSub}
                              {...subHrefProps}
                              color="gray-af-100"
                              className="footer-link"
                              {...(item.is_target_blank ? { target: '_blank' } : {})}
                              {...(item.is_no_follow ? { rel: 'nofollow' } : {})}
                            >
                              {item?.title}
                            </Typography>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Divider className="mt-80 mb-0" />
        <Grid container>
          <Grid row justify="between" className="py-24 flex-sm-row-reverse">
            <Grid column xs={12} sm={7}>
              <ul className="list-inline mb-20 mb-sm-0 footer-bottom-nav text-sm-right">
                {(bottomNavItems || []).map(item => (
                  <li className="list-inline-item">
                    <Typography
                      variant="body-small"
                      component={Link}
                      to={item.url}
                      color="gray-af-90"
                      title={item.title}
                      {...(item.is_target_blank ? { target: '_blank' } : {})}
                      {...(item.is_no_follow ? { rel: 'nofollow' } : {})}
                    >
                      {item.title}
                    </Typography>
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid column xs={12} sm={5}>
              <Typography variant="body-small" color="gray-af-90" className="mb-16 mb-sm-0">
                © {new Date().getFullYear()} {data?.site?.siteMetadata?.author}. All rights reserved.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </footer>
    </>
  );
};

Footer.propTypes = {
  hideCta: PropTypes.bool,
  locale: PropTypes.string,
  localizations: PropTypes.array,
};

Footer.defaultProps = {
  hideCta: false,
  locale: 'en',
  localizations: [],
};

export default Footer;
