import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import './style.scss';

const Flag = ({ iso, className, width, height, style, ...props }) => (
  <span
    {...props}
    className={clsx('flag-base', className, { [iso?.toUpperCase()]: iso })}
    style={{ ...style, '--width': width, '--height': height }}
  />
);

Flag.propTypes = {
  className: PropTypes.string,
  iso: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.object,
};

Flag.defaultProps = {
  className: undefined,
  iso: undefined,
  width: 16,
  height: undefined,
  style: undefined,
};

export default Flag;
