import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../../Typography';
import Grid from '../../Grid';
import './style.scss';
import Button from '../../Button';

const CtaBlock = ({ contents }) => {
  const [cta] = contents;
  return (
    <div className="cta-block py-64">
      <Grid container>
        <Grid row className="my-n24 my-md-n28 my-lg-0">
          <Grid column xs={12} md={8} lg={6} className="py-24 py-md-28 py-lg-0">
            <Typography variant="h4" component="div" color="white" className="mb-12 d-block">
              {cta?.title}
            </Typography>
          </Grid>
          <Grid
            column
            xs={12}
            md={4}
            lg={6}
            className="py-24 py-md-28 py-lg-0 d-flex justify-content-md-end align-items-center"
          >
            <Button href={cta?.cta_url_1}>{cta?.cta_label_1}</Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

CtaBlock.propTypes = {
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
};

CtaBlock.defaultProps = {
  contents: [],
};

export default CtaBlock;
