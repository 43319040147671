import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Typography from '../Typography';
import './style.scss';
import isRelativeUri from '../../utils/isRelativeUri';
import Image from '../Image';

const NavSubItem = ({ icon, label, title, href, description, target, rel, ...rest }) => {
  const isRelative = isRelativeUri(href);

  const url = isRelative && !href.startsWith('/') ? `/${href}` : href;
  const Component = isRelative ? Link : 'a';
  return (
    <Component
      {...rest}
      className="nav-subitem"
      title={title}
      {...(isRelative ? { to: url } : { href: url })}
      rel={rel}
      target={target}
    >
      {icon && (
        <div className="nav-subitem-icon">
          <Image src={icon?.url} width={16} height={16} alt={label} />
        </div>
      )}
      <div className="nav-subitem-text">
        <Typography variant="body" weight="medium" color="gray-af-110">
          {label}
        </Typography>
        {description && (
          <Typography variant="body" color="gray-af-100" className="mt-4">
            {description}
          </Typography>
        )}
      </div>
    </Component>
  );
};

NavSubItem.propTypes = {
  description: PropTypes.string,
  href: PropTypes.string.isRequired,
  icon: PropTypes.any,
  title: PropTypes.string,
  label: PropTypes.string.isRequired,
  rel: PropTypes.string,
  target: PropTypes.string,
};

NavSubItem.defaultProps = {
  description: undefined,
  icon: undefined,
  title: undefined,
  rel: undefined,
  target: undefined,
};

export default NavSubItem;
